import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import { LocalizedLink } from "../components/localized_link"
import SEO from "../components/seo"
import isMobile from "../utils/is_mobile"

const MenuPage = props => {
  const { data, path, location } = props
  const digitalQrMode = location?.search === "?digitalQr" && isMobile()

  const menu = data.prismicCartavini.data
  const sections = data.allPrismicWinesection.edges

  return (
    <Layout path={path} digitalQrMode={digitalQrMode} location={location}>
      <SEO title="Menu" />
      <div className="section not-that-much-margin-on-top">
        <div className="image-section">
          <div className="image-container">
            <Img fluid={menu.menu_image.localFile.childImageSharp.fluid} />
          </div>
        </div>
        <div className="container">
          <h1 className="custom dark">{menu.title}</h1>
        </div>
      </div>
      <div className="flex !flex-col items-center">
        {sections.map(section => {
          return (
            <LocalizedLink
              to={
                digitalQrMode
                  ? `/wines/${section?.node?.data?.slug}?digitalQr`
                  : `/wines/${section?.node?.data?.slug}`
              }
              style={{ textDecoration: "none" }}
              key={section?.node?.data?.slug}
            >
              <div className="custom_menu_cta !my-8">
                <p>{section?.node?.data?.title?.text}</p>
                <svg
                  width="85"
                  height="16"
                  viewBox="0 0 85 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
                    fill="#e46f4d"
                  />
                </svg>
              </div>
            </LocalizedLink>
          )
        })}
      </div>
      <div className="accent">
        <div className="container flex last-menu-section">
          <div className="image-section">
            <div className="image-container">
              <div className="yet-another-container">
                <Img
                  fluid={menu.section_image.localFile.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="description">
            <p className="title">{menu.section_title}</p>
            <p className="caption">{menu.section_description}</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MenuPage

export const query = graphql`
  query WinesQuery($localeRegex: String) {
    prismicCartavini(lang: { regex: $localeRegex }) {
      data {
        title
        section_title
        section_description
        menu_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allPrismicWinesection(filter: { lang: { regex: $localeRegex } }) {
      edges {
        node {
          data {
            slug
            title {
              text
            }
          }
        }
      }
    }
  }
`
